<template>
    <v-container class="accounting-entry__wrapper">
      <v-container id="regular-tables" fluid tag="section">
        <div class="d-flex justify-space-between">
          <h2> {{ $t('Account statement') }} : {{ this.currentAccount.name }}</h2>
        </div>
  
  
        <app-statement-filter @filterTable="applySearch($event)"></app-statement-filter>
  
  
  
        <v-simple-table :dark="$store.state.isDarkMode">
          <thead class="table-heading" v-if="!loading">
            <tr>
              <th v-for="(name, i) in tableHeaders" :key="i">
                {{ $t(name) }}
              </th>
            </tr>
          </thead>
          <div class="table__spinnerWrapper" v-if="loading">
            <app-spinner></app-spinner>
          </div>
          <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
            <template>
              <tr v-for="item in accountingEntries" :key="item.id">
                <td v-if="item.type == 'debit'">
                  {{ item.amount.toLocaleString() }}
                </td>
                <td v-if="item.type == 'debit'"></td>
                <td v-if="item.type == 'credit'"></td>
                <td v-if="item.type == 'credit'">
                  {{ item.amount.toLocaleString() }}
                </td>
                <td>
                  {{ item.entry.source }}
                </td>
                <td>{{ item.status }}</td>
  
                <td>
                  <span v-if="item.desc">{{ item.desc }}</span>
                </td>
                <td>
                  <span v-if="item.cost_center">{{ item.cost_center.name }}</span>
                </td>
                <td>{{ item.date }}</td>
                <td>
                  <v-icon v-if="returnAbility('entry:show') && (!item.entry.operate) && (item.entry.owner_type != null)"
                    color="green"
                    @click="goTodestination(item.entry.owner_type, item.entry.owner_id, item.entry.owner_type_id)">mdi-eye</v-icon>
                  <!-- <v-icon v-if="returnAbility('entry:update') && returnAbility('entry:show') && (item.entry.operate)" color="info" @click="$router.push(`/editAccountingEntry/${item.entry.id}`)">mdi-pencil</v-icon>
                  <app-delete-account-entry v-if="returnAbility('entry:delete') && (item.entry.operate)" :tableRecord="item"
                    serverRoute="/accounting/entry" @record-deleted="fetchAccountingEntry"></app-delete-account-entry> -->
                </td>
              </tr>
            </template>
          </v-fade-transition>
        </v-simple-table>
  
        <v-container class="d-flex justify-end mt-5">
          <v-row>
            <v-col md="5 ">
              <v-switch @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
            </v-col>
            <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="totalTablePages"
              :page="filterObject.page" @PaginationValue="applyPagination($event)"></app-pagination>
  
          </v-row>
        </v-container>
        <div class="account-entry__chip__wrapper">
        <div class="account-entry__chip">
          {{ $t('total debit') }}
          <span>{{ totalDebit }}</span>
        </div>
        <div class="account-entry__chip">
          {{ $t('total credit') }}
          <span>{{ totalCredit }}</span>
        </div>
        <div class="account-entry__chip">
          {{ $t('balance') }}
          <span>{{ remainingBalance }}</span>
        </div>
      </div>
      </v-container>
      <!-- </div> -->
  
  
  
  
    </v-container>
  </template>
  
  <script>
  import spinner from "../../../../components/base/spinner.vue";
  import statementFilter from "./statementFilter.vue";
  import axios from "axios";
  import pagination from "../../../../components/base/pagination.vue";
  import deleteAccountEntry from "./deleteAccountStatementModal.vue";
  import editAccount from "../accounting/components/editAccount.vue";
  export default {
    components: {
      appEditAccount: editAccount,
      appStatementFilter: statementFilter,
      appDeleteAccountEntry: deleteAccountEntry,
      appSpinner: spinner,
      appPagination: pagination,
    },
    data() {
      return {
        //
        is_all: false,
        currentAccount: {},
        totalDebit: 0,
        totalCredit: 0,
        remainingBalance: 0,
        //
        accountStatementId: this.$route.params.statementId,
        tableHeaders: [
          "debtor",
          "credit",
          "Document",
          "account name",
          "explain",
          "cost center",
          // "اسم المستخدم",
          "date",
          "settings",
        ],
        accountingEntries: [],
        totalTablePages: null,
        loading: false,
        paginated: true,
        filterObject: {
          debit: null,
          credit: null,
          desc: "",
          costCenter: null,
          page: 1,
        },
      };
    },
    props: {
      apiEndPoint: String,
    },
    methods: {
      goToAll() {
        if (this.is_all) {
          this.paginated = false;
        } else { this.paginated = true; }
        this.fetchAccountingEntry();
      },
  
      goTodestination(dis, id, type) {
        if (dis == "VOUCHER") {
          this.$router.push(`/edit-document/${id}?document_id=${type}`);
        }
        if (dis == "FINANCIALORDER") {
          this.$router.push(`/editAccountingEntry/${id}`);
        }
        if (dis == "INVOICE") {
          this.$router.push(`/edit-invoice/${id}?invoice_id=${type}`);
        }
      },
      applySearch(filterObject) {
        this.filterObject = filterObject;
        this.fetchAccountingEntry();
      },
      applyPagination(paginationValue) {
        this.filterObject.page = paginationValue;
        this.fetchAccountingEntry();
      },
      async fetchAccountingEntry() {
        try {
          this.loading = true;
          if (!this.filterObject.debit) delete this.filterObject.debit;
          if (!this.filterObject.credit) delete this.filterObject.credit;
          if (!this.filterObject.desc) delete this.filterObject.desc;
          if (!this.filterObject.costCenter) delete this.filterObject.costCenter;
          let params = {
            ...this.filterObject,
            paginated: this.paginated,
            sub_account_id: this.$route.params.subAccId,
          }
          this.filterObject.perPage = 10;
          const entries = await axios.get(
            `${this.apiEndPoint}${this.$route.params.statementId}`,
            {
              params: {
                ...params,
              },
            }
          );
          console.log("woooot fam", entries);
          this.totalDebit = entries.data.account.debit;
          this.totalCredit = entries.data.account.credit;
          this.remainingBalance = entries.data.account.balance;
          this.accountingEntries = entries.data.data;
          this.totalTablePages = entries.data.meta.total;
          this.filterObject.page = entries.data.meta.current_page;
          this.accountingEntries.forEach((item) => {
            item.uid = this.$uuid.v1();
          });
          this.currentAccount = entries.data.account;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.loading = false;
        }
      },
    },
    created() {
      this.fetchAccountingEntry();
    },
  };
  </script>
  
  <style></style>